<template>
  <div>
    <user-settings-menu />
  </div>
</template>

<script>
import UserSettingsMenu from '@/views/modules/user/user-settings-menu/UserSettingsMobileMenu.vue';

/**
 * User settings configurations and details
 *
 * @module views/modules/user-account/UserSettings
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'UserSettings',
  components: {
    UserSettingsMenu,
  },
};
</script>

<style lang="scss">
@media only screen and (min-width: 1201px) {
  tbody {
    display: flex;
    flex-wrap: wrap;
    tr {
      width: calc(50% - 1rem) !important;
      td {
        small {
          font-size: .875rem !important;
        }
        padding: 20px !important;
        .mr-4 {
          margin-right: 1.5rem !important;
        }
        .w-6 {
          width: 2rem !important;
          height: 2rem !important;
        }
      }
    }

    tr:nth-child(1n) {
      margin: 0.5rem 0.5rem 0.5rem 0rem !important;
    }

    tr:nth-child(2n) {
      margin: 0.5rem 0rem 0.5rem 0.5rem !important;
    }

  }

  .px-base {
    max-width: 30rem;
    margin: auto;
  }

}
</style>
