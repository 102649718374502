<template>
  <div>
    <mobile-menu-group
      v-for="(group, index) in groups"
      :id="group.TabIdName"
      :key="index"
      :class="{'mt-base': index > 0}"
      :header-text="group.headerText"
      :items="group.items"
      @feedback="increaseFeedbackModalKey()"/>

    <div class="px-base">
      <vs-button
        color="primary"
        type="border"
        class="w-full mt-base"
        @click="logout()">
        {{ $t('$General.Logout') }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import MobileMenuGroup from '@/views/modules/_components/MobileMenuGroup.vue';

/**
 * User settings for mobile menu
 *
 * @module views/modules/user-account/UserSettingsMenu
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} [groups=[...]] - groups to show in the menu
 * @vue-event {void} logout - logout
 */
export default {
  name: 'UserSettingsMenu',
  i18n: {
    messages: {
      en: {
        AccountText: 'Manage your account settings',
        BillingText: 'Manage your billing details',
        PurchasesText: 'Manage your shop',
        FeedbackText: 'Share your experience with us',
        TermsAndConditionsText: 'View our terms & conditions',
      },
    },
  },
  components: {
    MobileMenuGroup,
  },
  data() {
    return {
      groups: [
        {
          headerText: '',
          TabIdName: 'mobile-group-settings-account',
          items: [
            {
              title: this.$tc('$General.Account'),
              text: this.$t('AccountText'),
              icon: 'SettingsIcon',
              path: '/account',
            },
            {
              title: this.$tc('$General.Billing'),
              text: this.$t('BillingText'),
              icon: 'CreditCardIcon',
              path: '/billing',
            },
            {
              title: this.$tc('$General.Shop'),
              text: this.$t('PurchasesText'),
              icon: 'ShoppingCartIcon',
              path: '/purchases',
            },
            {
              title: this.$tc('$General.Feedback'),
              text: this.$t('FeedbackText'),
              icon: 'StarIcon',
              class: 'user-settings-account-feedback-button',
              cardIcon: 'ArrowRightCircleIcon',
              action: 'feedback',
            },
            {
              title: this.$tc('$General.TermsAndConditions'),
              text: this.$t('TermsAndConditionsText'),
              icon: 'FileTextIcon',
              class: 'user-settings-account-tac-button',
              cardIcon: 'ExternalLinkIcon',
              url: this.$enums.AppLinks.TERMS_AND_CONDITIONS,
            },
          ],
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      actionLogout: 'auth/logout',
    }),
    ...mapMutations({
      increaseFeedbackModalKey: 'INCREASE_FEEDBACK_MODAL_KEY',
    }),
    async logout() {
      await this.actionLogout();
      this.$router.push({ name: 'login' }).catch(() => {});
    },
  },
};

</script>
