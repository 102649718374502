<template>
  <div class="data-list-container">
    <vs-table
      ref="table"
      :data="items"
      @input="onItemClicked">

      <h4
        v-if="headerText"
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between">
        {{ headerText }}
      </h4>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr
          v-for="(tr, indextr) in data"
          :key="indextr"
          :data="tr"
          :class="tr.class">

          <vs-td class="flex">
            <feather-icon
              :icon="tr.icon"
              class="inline-block mr-4"
              svgClasses="w-6 h-6"/>

            <div>
              <p>
                <strong class="font-medium truncate">{{ tr.title }}</strong>
              </p>
              <small>{{ tr.text }}</small>
            </div>

            <feather-icon
              :icon="tr.cardIcon || 'ChevronRightIcon'"
              class="inline-block ml-auto"
              svgClasses="w-7 h-7"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
/**
 * Mobile menu group
 *
 * @module views/modules/_components/MobileMenuGroup
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} headerText - group header text
 * @vue-prop {Object[]} items - group items
 * @vue-event {void} onItemClicked - called on item clicked
 */
export default {
  name: 'MobileMenuGroup',
  props: {
    headerText: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onItemClicked(item) {
      if (item.url) {
        window.open(item.url, '_blank');
      } else if (item.action) {
        this.$emit(item.action, item);
      } else {
        this.$router.push(item.path);
      }
    },
  },
};

</script>

<style lang="scss">
.data-list-container {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 .5rem;
      padding: 0 0rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          > span {
            width: 100%;
            display: flex;
            align-items: center;
          }

          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
      }
    }

    .vs-table--thead{
      tr{
        background: none;
        box-shadow: none;
      }
    }
  }
}
</style>
